import { registerRootComponent } from "expo";
import { isEmbeddedWebApp } from "./src/common/util/deviceInfo";

if (isEmbeddedWebApp()) {
  if (window.location.pathname.startsWith("/a/admin")) {
    console.log("🚀 Starting embedded AdminApp");
    import("./src/backapp/admin/AdminApp").then((app) => registerRootComponent(app.default));
  } else if (window.location.pathname.startsWith("/a/partner")) {
    console.log("🚀 Starting embedded PartnerApp");
    import("./src/backapp/partner/PartnerApp").then((app) => registerRootComponent(app.default));
  }
} else {
  console.log("🚀 Starting web FrontApp");
  import("./src/frontapp/FrontApp").then((app) => registerRootComponent(app.default));
}
