import { Platform } from "react-native";
import { detectAppType } from "./device";

export function isIOS() {
  return isNativeIOS() || detectAppType().web === "ios" || detectAppType().native === "ios";
}

export function isAndroid() {
  return (
    isNativeAndroid() || detectAppType().web === "android" || detectAppType().native === "android"
  );
}

export function isWebAndroid() {
  return isWeb() && (detectAppType().web === "android" || detectAppType().native === "android");
}

export function isWeb() {
  return Platform.OS === "web";
}

export function isNative() {
  return Platform.OS !== "web";
}

export function isNativeAndroid() {
  return Platform.OS === "android";
}

export function isNativeIOS() {
  return Platform.OS === "ios";
}

// running in the container app
export function isEmbeddedWebApp() {
  return Platform.OS === "web" && window.navigator.userAgent.includes("Recirclable");
}
